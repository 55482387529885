@charset "UTF-8";

@mixin set-font($dir, $file-name, $name: false, $weight: normal, $style: normal, $stretch: normal) {
  @if ($name == false) {
    $name: $file-name;
  }
  @font-face {
    font-family: $name;
    src: url("#{$dir}/#{$file-name}.eot");
    src: url("#{$dir}/#{$file-name}.eot?#iefix") format("embedded-opentype"),
    url("#{$dir}/#{$file-name}.woff") format("woff"),
    url("#{$dir}/#{$file-name}.ttf") format("truetype"),
    url("#{$dir}/#{$file-name}.svg##{$file-name}") format("svg");
    font-weight: $weight;
    font-style: $style;
    font-stretch: $stretch;
  }
}

@function strip-units($val) {
  @return ($val / ($val * 0 + 1));
}

@function em($pxval, $base: 16) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return $pxval / $base * 1em;
}

@mixin respondTo($media, $default: false) {
  @if $media == tablet_p {
    @media (min-width: $tablet-p) {
      @content;
    }
  } @else if $media == tablet_l {
    @media (min-width: $tablet-l) {
      @content;
    }
  } @else if $media == desktop {
    @media (min-width: $desktop) {
      @content;
    }
  } @else if $media == wide {
    @media (min-width: $wide) {
      @content;
    }
  } @else {
    @media ($media) {
      @content;
    }
  }
  @if $default == default {
    .has-no-mediaqueries & {
      @content;
    }
  }
}

@mixin font($size: false, $line: false, $f: false, $w: false, $s: false, $v: false) {
  @if ($v and $s and $w and $size and $line and $f) {
    font: $s $v $w #{$size}/#{$line} $f;
  } @else if ($s and $w and $size and $line and $f) {
    font: $s $w #{$size}/#{$line} $f;
  } @else if ($w and $size and $line and $f) {
    font: $w #{$size}/#{$line} $f;
  } @else if ($f == false) {
    @if ($size) {
      font-size: $size;
    }
    @if ($line) {
      line-height: $line;
    }
    @if ($w) {
      font-weight: $w;
    }
    @if ($s) {
      font-style: $s;
    }
    @if ($v) {
      font-variant: $v;
    }
  }
}

@mixin hover() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin fullhover($classname: "is-active") {
  &:hover,
  &:focus,
  &:active,
  &.#{$classname} {
    @content;
  }
}

@mixin after($content: " ") {
  &::after {
    content: $content;
    @content;
  }
}

@mixin before($content: " ") {
  &::before {
    content: $content;
    @content;
  }
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min--moz-device-pixel-ratio: 1.5), screen and (-o-min-device-pixel-ratio: 3/2), screen and (min-device-pixel-ratio: 1.5), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

@mixin icon($retina-group){
  $normal-sprite: nth($retina-group, 2);
  $width: nth($normal-sprite, 5);
  $height: nth($normal-sprite, 6);
  margin: $height/-2 $width/-2;
  @include retina-sprite($retina-group);
}

@charset "UTF-8";
/* --------------------
   Quest
   -------------------- */


.quest-block2 {
	height: 297px;
}
.hole {
	position: absolute;
	z-index: 1;
	left: 454px;
	background: url(../images/hole.png);
	width: 165px;
	height: 82px;
}
.black-block {
	position: absolute;
	left: 0;
	z-index: -10;
	margin-top: -128px;
	background: black;
	width: 100%;
	height: 397px;
	min-width: 1092px;
	overflow: hidden;
}
.block-img {
	margin: 0 auto;
	left: 291px;
	position: relative;
	width: 599px;
	height: 397px;
}

.quest-map2 {
	position: absolute;
	top: 302px;
	background: url(../images/address-background.png);
	width: 100%;
	height: 52px;
	padding: 14px 32px;
}



.quest-info2 strong {
	font-size: 30px;
}

.quest-info2 .quest-person {
	margin-top: 9px;
}

.quest-map2 .quest-address {
	line-height: 18px;
	font-size: 15px;
	width: 230px;
}

.map-link {
	display: inline-block;
	padding-left: 48px;
	padding-top: 5px;
	font-size: 13px;
	color: #dc6161;
}

.map-link:hover {
	cursor: pointer;
	text-decoration: underline;
}





.schedule-tip {
	font: 20px agora-regular;
	text-align: center;
}

.current a {
	color: white;
}

.sc-line {
	clear: both;
	height: 50px;
	margin-top: 5px;
	margin-bottom: 5px;
}
.sc-line td {
	vertical-align: middle;
}

.sc-date {
	width: 120px;
	vertical-align: middle;
	@include font(44px, 50px, $gotham, 300);
	letter-spacing: -2px;
	color: white;
}

.sc-weekend .sc-date, .sc-weekend .sc-price {
	color: #b8b9de;
}
.sc-weekend .icon-ruble-small {
	background: url(../images/icon-ruble-small2.png);
}

.sc-weekend .sc-hline {
	border-color: #b8b9de;
}


.sc-weekday {
	width: 120px;
	font-size: 13px;
	margin-right: 9px;
}
.sc-time {
	width: 62px;
	height: 45px;
	border: black solid 1px;
	border-radius: 7px;
	@include font(17px, 45px, $gotham, 300);
	color: black;
	/*border: 2px solid #ff5959;*/
	text-align: center;
	letter-spacing: -1px;
	box-shadow: none;
	
	/*display: inline-block;
	text-shadow: 0 6px 3px black;*/
	
	&.is-active {
		border-color: white;
		color: white;
		box-shadow: 2px 3px 3px rgba(0,0,0,0.3),
				inset 2px 3px 3px rgba(0,0,0,0.3);
		cursor: pointer;

		&:hover {
			background-color: white;
			color: #2b303f;
			box-shadow: 2px 3px 3px rgba(0,0,0,0.3);
		}

		&:active {
			box-shadow: 2px 3px 3px rgba(0,0,0,0.3),
						inset 2px 2px 5px rgba(0,0,0,0.2);
		}
	}
}


.hline {
	width: 82px;
	margin: 0 auto;
	border-bottom: white solid 5px;
}

.quest-descripion {
	margin-top: 23px;
	padding-left: 23px;
	font-size: 14px;
	line-height: 21px;
}

.icon-ruble-small {
	display: inline-block;
	vertical-align: text-top;
	width: 9px;
	height: 11px;
	background: url(../images/icon-ruble-small.png);
}

.sc-price {
	padding-top: 11px;
	padding-bottom: 18px;
}

.sc-hline {
	display: inline-block;
	border-bottom:
	solid white 1px;
	margin-bottom: 3px;
}



.quest-block3 {
	position: absolute;
	margin-top: -128px;
	/*background: black;*/
	width: auto;
	height: 397px;
	min-width: 1092px;
	overflow: hidden;
}


#chimera .icon-person-grey {
	background-image: url(../images/icon-person-black.png);
}



.winners-tab {
	width: 1000px;
	margin: 0 auto;
	padding-bottom: 30px;
	padding-top: 20px;
}

.schedule-tab {
	clear: both;
	width: 850px;
	margin: 0 auto;
	padding-bottom: 30px;
	/*border-top: #5c6170 solid 3px;*/
	padding-top: 20px;
}
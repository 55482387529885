@charset "UTF-8";
/* --------------------
   Certificate
   -------------------- */


.certificate {
	clear: both;
	height: 850px;
	margin: -13px auto 0;
	width: 1000px;
	text-align: center;

	&__title {
		text-align: center;
		margin-bottom: 81px;
		@include font(44px, 46px, $gotham, 500);
		letter-spacing: -1.9px;
		text-shadow: 2px 2px 5px black;
	}
	
	&__text {
		width: 830px;
		margin: 0 auto;
		margin-bottom: 30px;
		@include font(22px, 34px, $gotham, 400);
		letter-spacing: -0.2px;
		color: #eeeedd;
		text-shadow: 2px 2px 5px black;
		text-align: left;
	}

	a {
		color: #eeeedd;
	}

	&__form {
		width: 412px;
		margin: 80px auto 0;
	}

	input {
		width: 376px;
		height: 54px;
		margin-top: 12px;
		margin-bottom: 10px;
		padding: 0 18px;
		background: rgba(0, 0, 0, 0.3);
		@include font(20px, 35px, $gotham, 300);
		color: #7b8092;
		border: none;
		border-radius: 7px;
		transition: box-shadow 0.5s;
		outline: none;

		&:focus {
			box-shadow: 0 0 6px #373f51;
		}
	}

	input[type="submit"] {
		width: 412px;
		height: 55px;
		background: #151b26;
		margin-top: 24px;
		margin-bottom: 10px;
		@include font(22px, 55px, $gotham, 400);
		letter-spacing: -0.8px;
		color: white;
		text-transform: uppercase;
		border-radius: 7px;
		transition: all 0.3s;
		cursor: pointer;

		&:hover {
			box-shadow: 0 0 7px rgba(255, 255, 255, 0.75); /*#feedc8*/
		}

		&:active {
			background: #0f141c;
			box-shadow: none;
		}
	}
}







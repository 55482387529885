@charset "UTF-8";
/* --------------------
   Send comment form
   -------------------- */

.send-comment {
	width: 850px;
	margin: 0 auto;
	padding: 30px 0 20px;

	&__title {
		margin: 0 0 20px;
		@include font(30px, 30px, $gotham, 500);
	}

	&__button-holder {
		text-align: center;
	}
}
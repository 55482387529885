@charset "UTF-8";
/* --------------------
   Отзывы
   -------------------- */

.comments {
	clear: both;
	width: 850px;
	margin: 0 auto;
	padding-top: 20px;
	
	&__block {
		margin-bottom: 20px;
		border-radius: 10px;
		background-color: rgba(18,21,31,0.6);
		font-size: 0;

		&:last-child {
			margin-bottom: 45px;

			@include after() {
				position: relative;
				top: 35px;
				display: block;
				height: 3px;
				background-color: #5c6170;
				border-radius: 2px;
				box-shadow: 1px 2px 3px black;
			}
		}
	}

	&__content {
		position: relative;
		padding: 20px;
	}

	&__name {
		display: inline-block;
		vertical-align: top;
		padding: 0 20px 10px 0;	
		@include font(20px, 20px, $gotham, 500);
	}

	&__date {
		display: inline-block;
		vertical-align: top;
		padding-bottom: 10px;
		@include font(15px, 24px, $gotham, 400);
		color: #9390a3;
	}

	&__text {
		padding-top: 10px;
		@include font(14px, 21px, $gotham, 400);
	}

}
@charset "UTF-8";
/* --------------------
   Tabs
   -------------------- */

.tabs {

	&__list {
		position: relative;
		left: 50%;
		float: left; 
		margin-top: 21px;
	}

	&__tab {
		display: inline-block;
		position: relative;
		left: -50%;
		margin: 0 10px;
		padding-top: 20px;
		padding-bottom: 13px;
		@include font(17px, 19px, $gotham, 500);
		color: #807790;
		transition: color 0.4s;
		text-align: center;
		text-shadow: 1px 2px 3px black;
		text-transform: uppercase;
		cursor: pointer;
		
		&:hover {
			color: white;
		}

		&.current {
			color: white;
			border-bottom: #5c6170 solid 6px;
		}
	}

	&__line {
		clear: both;
		margin: 0 auto;
		background: #5c6170;
		width: 850px;
		height: 3px;
		box-shadow: 1px 2px 3px black;
	}

	&__content {
		display: none;

		&.current {
			display: block;
		}
	}
}

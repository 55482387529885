@charset "UTF-8";
/* --------------------
   Contacts
   -------------------- */
   

.contacts {
	
	&__container {
		width: 1022px;
		margin: -48px auto 0;
		font-family: $gotham;
		font-weight: 400;
		color: #eeeedd;

		@include clearfix();
	}

	&__block {
		float: left;
		width: 420px;
		height: 527px;
		margin: 0 15px 37px;
		padding: 30px;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.25);
	}

	&__title {
		height: 112px;
	}

	&__main {
		margin-bottom: 7px;
		@include font(18px, 20px, $gotham, 300);
		text-align: center;
	}

	&__capt {
		margin-bottom: 9px;
		@include font(33px, 35px, $gotham, 500);
		text-align: center;	
	}

	&__city {
		text-align: center;
		font-size: 20px;
	}

	&__item {
		height: 45px;
		margin-top: 24px;
		padding: 15px 0 0 78px;
		@include font(21px, 23px, $gotham, 300);

		&_address {
			background: url(../images/contact-icon-address.png) 3px center no-repeat;
			padding-top: 10px;
			height: 50px;
		}
		&_phone {
			background: url(../images/contact-icon-phone.png) 9px -4px no-repeat;
			font-size: 17px;
			padding-top: 22px;
			height: 43px;
		}
		&_mail {
			background: url(../images/contact-icon-mail.png) no-repeat;
		}
		&_vk {
			background: url(../images/contact-icon-vk.png) no-repeat;
		}
		&_instagram {
			background: url(../images/contact-icon-instagram.png) no-repeat;
		}

		a {
			padding-bottom: 3px;
			border-bottom: 1px solid #eeeedd;
			color: #eeeedd;
			text-decoration: none;
		}
	}

	&__map {
		margin: 0 55px;
		height: 550px;
	}

	&__hidden {
		display: none;
	}

	&__infowindow {
		max-width: 250px;
		padding: 10px 0;
		color: black;

		h3 {
			@include font(13px, 15px, $gotham, 700);
			font-weight: 700;
			padding-bottom: 10px;
		}

		p {
			@include font(13px, 15px, $gotham, 400);
		}
	}
}
@charset "UTF-8";
/* --------------------
   Youtube video
   -------------------- */
   

#playYoutube {
	position: absolute;
	/*top: -397px;*/
	left: 538px;
	width: 550px;
	/*height: 397px;*/
	height: 300px;
	cursor: pointer;
	background: url(../images/YouTube-icon-dark.png) 50% 70% no-repeat;
	opacity: 0.4;
	-webkit-transition: opacity 1s;
		 -o-transition: opacity 1s;
			transition: opacity 1s;
}

#playYoutube:before {
	display: block;
	width: 100%;
	height: 100%;
	background: url(../images/YouTube-icon-full_color.png) 50% 70% no-repeat;
	opacity: 0;
	-webkit-transition: opacity 1s;
		 -o-transition: opacity 1s;
			transition: opacity 1s;
	content: '';
}


#playYoutube:hover:before {
	opacity: 1;
}

#playYoutube:hover {
	opacity: 0.8;
}

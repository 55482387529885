@charset "UTF-8";
/* --------------------
   Quest block
   -------------------- */



.quest-block {
	position: relative;
	float: left;
	width: 472px;
	height: 311px;
	padding: 6px;
	border: white solid 6px;
	margin: 0 25px;
	margin-bottom: 50px;
	transition: box-shadow 0.3s ease-out;
}


.quest-block:hover {
	box-shadow: 0 0 10px white;
}
.quest-block:hover img {
	-webkit-transform: scale(1.02);
	-ms-transform: scale(1.02);
	transform: scale(1.02);
}

.quest-title span {
	display: block;
	transition: all 1.5s ease-out;
}

.quest-block .quest-title {
	transition: all 1.5s ease-out;
}



.quest-block:hover .quest-title {
	-webkit-transform: scale(1.02);
	-ms-transform: scale(1.02);
	transform: scale(1.02);
}



.inactive:hover {
	box-shadow: none;
}

.inactive:hover img, .inactive:hover .quest-title, .quest-block:hover .quest-title span {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}



.quest-img {
	position: relative;
	transition: -webkit-transform 1.5s ease-out;
	transition: transform 1.5s ease-out;
}

.quest-title {
	position: relative;
	color: white;
	font-size: 40px;
	letter-spacing: -2px;
	line-height: 52px;
	font-family: CatorzeSemiBold;  /* найти замену */
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	width: 100%;
	height: 52px;
	background: rgba(0,0,0,0.5);
	width: 472px;
	margin: 118px auto 0 auto;
}

.quest-description {
	text-align: left;
	z-index: 20;
	text-decoration: none;
	width: 100%;
	height: 100%;
	display: block;
	left: 0;
	top: 0;
	position: absolute;
}

.quest-info {
	position: absolute;
	left: 34px;
	bottom: 22px;
}

.quest-person {
	display: block;
	@include font(14px, 16px, $gotham, 500);
	color: white;
}

.quest-map {
	display: block;
	margin-top: 13px;
	@include font(12px, 14px, $gotham, 500);
	color: white;
}

.quest-address {
	display: inline-block;
	width: 230px;
	line-height: 19px;
	font-size: 15px;
	letter-spacing: -1px;
}


.quest-description strong {
	font-size: 24px;
	margin-left: 5px;
}


.icon-person {
	display: inline-block;
	vertical-align: text-bottom;
	width: 18px;
	height: 24px;
	margin-right: 2px;
	background: url(../images/icon-person.png) center no-repeat;
}

.icon-person-grey {
	display: inline-block;
	vertical-align: text-bottom;
	width: 18px;
	height: 24px;
	margin-right: 2px;
	background: url(../images/icon-person-grey.png) center no-repeat;
}

.icon-map {
	display: inline-block;
	/*vertical-align: top;*/
	width: 23px;
	height: 23px;
	margin-right: 9px;
	background: url(../images/icon-map.png);
}

.icon-clock {
	display: inline-block;
	width: 39px;
	height: 39px;
	background: url(../images/icon-clock.png);
	margin: 0 auto;
}




.icon-person2 {
	display: inline-block;
	vertical-align: text-bottom;
	width: 25px;
	height: 27px;
	margin-right: 2px;
	background: url(../images/icon-person2.png) center no-repeat;
}

.icon-person-grey2 {
	display: inline-block;
	vertical-align: text-bottom;
	width: 25px;
	height: 27px;
	margin-right: 2px;
	background: url(../images/icon-person-grey2.png) center no-repeat;
}

.icon-map2 {
	display: inline-block;
	/*vertical-align: top;*/
	width: 30px;
	height: 30px;
	margin-right: 14px;
	background: url(../images/icon-map2.png);
}

.icon-clock2 {
	display: inline-block;
	vertical-align: bottom;
	width: 35px;
	height: 35px;
	background: url(../images/icon-clock2.png);
	margin-right: 15px;
	margin-top: 5px;
}


.quest-block .quest-info2 {
	margin-left: -24px;
}

.quest-info2 {
	position: absolute;
	bottom: 33px;
	left: 24px;
	width: 100%;
	color: white;
	font-size: 17px;
	text-align: center;
	margin-top: 12px;
}
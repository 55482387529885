@charset "UTF-8";
/* --------------------
   Winners
   -------------------- */

#photo-view {
	position: fixed;
	z-index: 30;
	top: 0;
	left: 0;
	display: none;
	width: 100%;
	height: 100%;
	cursor: pointer;
	overflow-y: scroll;
}

#photo {
	position: relative;
	margin: 0 auto;
	z-index: 35;
	display: block;
	height: auto;
}

#photo img {
	width: 100%;
	height: auto;
}
.magnify .info {
	opacity: 0;
}
.magnify:hover .info {
	opacity: 1;
}

#photo .info,
.magnify .info {
	position: absolute;
	width: 100%;
	//height: 100%;
	padding: 10px;
	box-sizing: border-box;
	-webkit-transition: all 1s, opacity 0.5s;
		 -o-transition: all 1s, opacity 0.5s;
			transition: all 1s, opacity 0.5s;
}

.info .caption {
	height: 0;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 1s;
		 -o-transition: all 1s;
			transition: all 1s;
}

.big .info .caption {
	height: 30px;
	opacity: 1;
	visibility: visible;
}

.big .info {
	padding: 30px !important;
}
.big .time span,
.big .date span {
	font-size: 30px !important;
}


#photo .time,
#photo .date,
.magnify .time,
.magnify .date {
	@include font(22px, 24px, $gotham, 500);
	text-shadow: 0 0 20px black,
				 0 0 20px black,
				 0 0 20px black;
	-webkit-transition: all 1s;
		 -o-transition: all 1s;
			transition: all 1s;
}

#photo .time span,
#photo .date span {
	-webkit-transition: all 1s;
		 -o-transition: all 1s;
			transition: all 1s;
}

#photo .time,
.magnify .time {
	position: absolute;
}
#photo .time span,
.magnify .time span {
	line-height: 50px;
	display: inline-block;
}
#photo .time span:before,
.magnify .time span:before {
	background: url(../images/clock.png);
	content: '';
	display: inline-block;
	width: 60px;
	height: 67px;
	vertical-align: middle;
	margin: -7px;
}

#photo .date,
.magnify .date {
	position: relative;
	float: right;
	text-align: right;
}

#photo .date span:before,
.magnify .date span:before{
	background: url(../images/calendar.png);
	content: '';
	display: inline-block;
	width: 64px;
	height: 64px;
	vertical-align: middle;
	margin: -7px;
}

.magnify {
	position: relative;
	cursor: pointer;
	width: 320px;
	height: 320px;
	display: inline-block;
	margin: 4px;
}
.magnify img {
	width: 100%;
	height: 100%;
}

.img-overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 25;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	min-height: 760px;
}

.years,
.months {
	width: 850px;
	margin: 0 auto;
}

.years a,
.months a {
	font-size: 15px;
	padding: 10px 0;
	text-shadow: 1px 2px 3px black;
	text-decoration: none;
}


.months {
	display: table;
	width: 870px;
	margin-bottom: 20px;
}

.months li {
	display: table-cell;
	float: none;
}
.years {
	text-align: center;
}
.years li {
	width: 60px;
	display: inline-block;
}

.years li a,
.months li a {
	display: block;
	text-align: center;
	font-family: $gotham;
	font-weight: 500;
	padding: 8px 0;
	margin: 0 8px;
	color: #807790;
	border-bottom: transparent solid 6px;
	-webkit-transition: all 0.4s;
		 -o-transition: all 0.4s;
			transition: all 0.4s;
}

.years li a:hover,
.months li a:hover {
	color: white;
}

.years li a.current,
.months li a.current {
	color: white;
	border-bottom: #5c6170 solid 6px;
}


.photos {
	position: relative;
	min-height: 200px;
	opacity: 1;
	-webkit-transition: all 1s;
		 -o-transition: all 1s;
			transition: all 1s;
}
.loading:before {
	position: absolute;
	top: 0;
	left: 0;
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	z-index: 100;
	background: url(../images/loading-big.gif) center 100px no-repeat;
	background-size: 60px 64px;
}

.loading * {
	opacity: 0.7;
}

.img-loader {
	position: absolute;
	left: -9999px;
	width: 0;
	height: 0;
	overflow: hidden;
}
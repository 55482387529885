@charset "UTF-8";

@mixin clearfix() {
  zoom: 1;
  @include before() {
    content: " ";
    display: table;
  }
  @include after() {
    content: " ";
    clear: both;
    display: table;
  }
}

@mixin container() {
  position: relative;
  margin: 0 auto;
  max-width: $row-width;
}

@mixin no-tap-highlight() {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  ::selection {
    background: transparent;
    text-shadow: none;
  }
}

@mixin hide-text() {
  text-indent: -9000px;
  white-space: nowrap;
  overflow: hidden;
}

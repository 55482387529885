@charset "UTF-8";
$font-dir: "../fonts";

// Font Weights
// 100 = thin
// 200 = extra-light
// 300 = light
// 400 = normal, book, regular
// 500 = medium
// 600 = demi-bold
// 700 = bold
// 800 = heavy
// 900 = black

//@include set-font($dir, $file-name, $name: false, $weight: normal, $style: normal)
//@include set-font($font-dir, "PFDinTextPro-MedItalic", "PFDinTextPro", 500, italic);



/* font: Gotham Pro Regular */
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamProRegular/GothamProRegular.eot");
	src: url("../fonts/GothamProRegular/GothamProRegular.eot?#iefix")format("embedded-opentype"),
	url('../fonts/GothamProRegular/GothamProRegular.svg') format('svg'),
	url("../fonts/GothamProRegular/GothamProRegular.woff") format("woff"),
	url("../fonts/GothamProRegular/GothamProRegular.ttf") format("truetype");
	font-style: normal;
	font-weight: 400;
}
/* font: Gotham Pro Bold */
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamProBold/GothamProBold.eot");
	src: url("../fonts/GothamProBold/GothamProBold.eot?#iefix")format("embedded-opentype"),
	url("../fonts/GothamProBold/GothamProBold.woff") format("woff"),
	url("../fonts/GothamProBold/GothamProBold.ttf") format("truetype");
	font-style: normal;
	font-weight: 700;
}
/* font: Gotham Pro Black */
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamProBlack/GothamProBlack.eot");
	src: url("../fonts/GothamProBlack/GothamProBlack.eot?#iefix")format("embedded-opentype"),
	url("../fonts/GothamProBlack/GothamProBlack.woff") format("woff"),
	url("../fonts/GothamProBlack/GothamProBlack.ttf") format("truetype");
	font-style: normal;
	font-weight: 900;
}
/* font: Gotham Pro Italic */
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamProItalic/GothamProItalic.eot");
	src: url("../fonts/GothamProItalic/GothamProItalic.eot?#iefix")format("embedded-opentype"),
	url("../fonts/GothamProItalic/GothamProItalic.woff") format("woff"),
	url("../fonts/GothamProItalic/GothamProItalic.ttf") format("truetype");
	font-style: italic;
	font-weight: 400;
}
/* font: Gotham Pro Medium */
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamProMedium/GothamProMedium.eot");
	src: url("../fonts/GothamProMedium/GothamProMedium.eot?#iefix")format("embedded-opentype"),
	url('../fonts/GothamProMedium/GothamProMedium.svg') format('svg'),
	url("../fonts/GothamProMedium/GothamProMedium.woff") format("woff"),
	url("../fonts/GothamProMedium/GothamProMedium.ttf") format("truetype");
	font-style: normal;
	font-weight: 500;
}
/* font: Gotham Pro Light */
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamProLight/GothamProLight.eot");
	src: url("../fonts/GothamProLight/GothamProLight.eot?#iefix")format("embedded-opentype"),
	url('../fonts/GothamProLight/GothamProLight.svg') format('svg'),
	url("../fonts/GothamProLight/GothamProLight.woff") format("woff"),
	url("../fonts/GothamProLight/GothamProLight.ttf") format("truetype");
	font-style: normal;
	font-weight: 300;
}
/* font: Gotham Pro Medium Italic */
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamProMediumItalic/GothamProMediumItalic.eot");
	src: url("../fonts/GothamProMediumItalic/GothamProMediumItalic.eot?#iefix")format("embedded-opentype"),
	url("../fonts/GothamProMediumItalic/GothamProMediumItalic.woff") format("woff"),
	url("../fonts/GothamProMediumItalic/GothamProMediumItalic.ttf") format("truetype");
	font-style: italic;
	font-weight: 500;
}
/* font: Gotham Pro Light Italic */
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamProLightItalic/GothamProLightItalic.eot");
	src: url("../fonts/GothamProLightItalic/GothamProLightItalic.eot?#iefix")format("embedded-opentype"),
	url("../fonts/GothamProLightItalic/GothamProLightItalic.woff") format("woff"),
	url("../fonts/GothamProLightItalic/GothamProLightItalic.ttf") format("truetype");
	font-style: italic;
	font-weight: 300;
}
/* font: Gotham Pro Bold Italic */
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamProBoldItalic/GothamProBoldItalic.eot");
	src: url("../fonts/GothamProBoldItalic/GothamProBoldItalic.eot?#iefix")format("embedded-opentype"),
	url("../fonts/GothamProBoldItalic/GothamProBoldItalic.woff") format("woff"),
	url("../fonts/GothamProBoldItalic/GothamProBoldItalic.ttf") format("truetype");
	font-style: italic;
	font-weight: 700;
}
/* font: Gotham Pro Black Italic */
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamProBlackItalic/GothamProBlackItalic.eot");
	src: url("../fonts/GothamProBlackItalic/GothamProBlackItalic.eot?#iefix")format("embedded-opentype"),
	url("../fonts/GothamProBlackItalic/GothamProBlackItalic.woff") format("woff"),
	url("../fonts/GothamProBlackItalic/GothamProBlackItalic.ttf") format("truetype");
	font-style: italic;
	font-weight: 900;
}



/* font-family: "CatorzeSemiBold"; */
@font-face {
	font-family: "CatorzeSemiBold";
	src: url("../fonts/CatorzeSemiBold/Catorze27Style1SemiBold.eot");
	src: url("../fonts/CatorzeSemiBold/Catorze27Style1SemiBold.eot?#iefix")format("embedded-opentype"),
	url("../fonts/CatorzeSemiBold/Catorze27Style1SemiBold.woff") format("woff"),
	url("../fonts/CatorzeSemiBold/Catorze27Style1SemiBold.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}
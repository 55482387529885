@charset "UTF-8";
/* --------------------
   Button
   -------------------- */

.button {
	outline: none;
	border: none;
	border-radius: 7px;
	@include font(24px, 26px, $gotham, 400);
	color: white;
	cursor: pointer;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.67);
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45);
	transition: background 0.3s, color 0.3s, box-shadow 0.3s;

	&:hover {
		background-color: white;
		color: #2b303f;
		box-shadow: 2px 3px 3px rgba(0,0,0,0.3);
	}

	&:active {
		box-shadow: 2px 3px 3px rgba(0,0,0,0.3),
					inset 2px 2px 5px rgba(0,0,0,0.2);
	}

	&_red {
		background: #962d2d;
		&:hover {
			background: #962d2d;
			color: white;
			box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45),
									0 0 7px rgba(254, 237, 200, 0.75); /*#feedc8*/
		}
	}
}
@charset "UTF-8";
/* --------------------
   Form
   -------------------- */

.form {
	box-sizing: border-box;

	label {
		@include font(13px, 15px, $gotham, 300);
		color: white;
	}

	input {
		box-sizing: border-box;
		width: 100%;
		height: 38px;
		margin-top: 12px;
		margin-bottom: 22px;
		padding: 0 12px;
		border: none;
		outline: none;
		background: #161821;
		@include font(14px, 14px, $gotham, 400);
		color: #9390a3;
		transition: box-shadow 0.5s;

		&.is-error {
			box-shadow: 0 0 6px red;
		}

		.send-comment & {
			background-color: rgba(18,21,31,0.6);
			padding: 25px 20px;
			@include font(20px, 20px, $gotham, 400);
			border-radius: 10px;
		}
	}

	input[type="submit"] {
		min-height: 50px;
		border-radius: 7px;
		background: #962d2d;
		@include font(24px, 26px, $gotham, 400);
		color: white;
		text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.67);
		box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45);

		.send-comment & {
			display: inline-block;
			width: auto;
			padding: 0 40px;
			text-transform: uppercase;
		}
	}

	textarea {
		box-sizing: border-box;
		resize: vertical;
		width: 100%;
		height: 64px;
		margin-top: 12px;
		margin-bottom: 22px;
		padding: 10px 12px;
		border: none;
		outline: none;
		background: #161821;
		@include font(14px, 14px, $gotham, 400);
		color: #9390a3;
		transition: box-shadow 0.5s;

		&.is-error {
			box-shadow: 0 0 6px red;
		}

		.send-comment & {
			background-color: rgba(18,21,31,0.6);
			padding: 15px 20px;
			@include font(20px, 20px, $gotham, 400);
			border-radius: 10px;
			min-height:200px;
		}
	}
}
@charset "UTF-8";
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce-up {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -30%);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes bounce-down {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 30%);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes bounce-left {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-30%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes bounce-right {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(30%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes loader-bounce {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(.3)
  }
}

@charset "utf-8";

// переменные
@import "utility/variables";

// mixins
@import "utility/sprite";
@import "utility/mixins";
@import "utility/snippets";

/* ====================
   Подключение шрифтов
   ==================== */
@import "utility/fonts";

/* ====================
   Reset
   ==================== */
@import "utility/reset";

/* ====================
   Base styles
   ==================== */
@import "sections/base";

/* ====================
   Стили блоков сайта
   ==================== */

@import "blocks/button";
@import "blocks/certificate";
@import "blocks/contacts";
@import "blocks/comments";
@import "blocks/form";
@import "blocks/modal";
//@import "blocks/popup";
@import "blocks/quest-block";
@import "blocks/quest";
@import "blocks/rules";
@import "blocks/send-comment";
@import "blocks/tabs";
@import "blocks/winners";
@import "blocks/youtube-video";

/* ====================
   Стили секций сайта
   ==================== */
//@import "sections/header";
//@import "sections/layout";
@import "sections/footer";

/* ====================
   CSS3 анимации
   ==================== */
@import "utility/animations";

/* ====================
   Медиа-запросы
   ==================== */

@charset "UTF-8";
/* --------------------
   Footer
   -------------------- */

.footer {
	width: 1058px;
	height: 90px;
	margin: 50px auto 35px;
	font-size: 14px;
}

.footer b {
	display: inline-block;
	margin-bottom: 8px;
}

.payment {
	float: left;
	width: 310px;
	margin-top: 12px;
}

.icon-ruble {
	display: inline-block;
	vertical-align: middle;
	width: 23px;
	height: 33px;
	background: url(../images/icon-ruble.png);
}

.icon-ruble2 {
	display: inline-block;
	vertical-align: baseline;
	width: 13px;
	height: 18px;
	margin-left: 5px;
	background: url(../images/icon-ruble2.png);
}

.address {
	float: left;
	width: 438px;
	text-align: center;
	font-size: 13px;
	line-height: 16px;
}

.social {
	float: left;
	text-align: right;
	width: 310px;
	margin-top: 12px;
}

.icon-instagram {
	display: inline-block;
	vertical-align: middle;
	background: url(../images/icon-instagram.png);
	height: 32px;
	width: 32px;
	margin-left: 8px;
	border-radius: 8px;
	transition: box-shadow 0.3s ease-out;
}

.icon-vk {
	display: inline-block;
	vertical-align: middle;
	background: url(../images/icon-vk.png);
	height: 32px;
	width: 32px;
	margin-left: 8px;
	border-radius: 8px;
	transition: box-shadow 0.3s ease-out;
}

.icon-vk:hover, .icon-instagram:hover, .privacy-policy:hover {
	box-shadow: 0 0 6px rgba(255, 255, 255, 0.6);
}
.privacy-policy:hover {
	background: white;
}

.privacy-policy {
	background: #6d707a;
	color: #2b303f;
	margin: 0 auto;
	width: 230px;
	height: 18px;
	line-height: 18px;
	margin-top: 6px;
	transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
	cursor: pointer;
	margin-bottom: 15px;
}
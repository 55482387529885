@charset "UTF-8";
/* --------------------
   Base styles
   -------------------- */



html, body {
	height: 100%;
}

html {
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
}

@media screen and (max-device-width: 480px) {
	html {
		-webkit-text-size-adjust: none;
		-ms-text-size-adjust: none;
	}
}

body {
	//position: relative;
	min-width: 1088px;
	background: url(../images/bg.jpg);
	font-family: $gotham;
	font-weight: 400;
	color: white;

	&.is-no-scroll {
		overflow: hidden;
	}
}

b {
	font-family: $gotham;
	font-weight: 500;
}

h1, h2 {
	text-align: center;
	text-shadow: 2px 2px 5px black;
}

h1 {
	margin-bottom: 30px;
	@include font(42px, 44px, $gotham, 500);
	letter-spacing: -2.4px;
}
h2 {
	margin-bottom: 116px;
	@include font(29px, 36px, $gotham, 300);
	letter-spacing: -0.8px;
}

a {
	color: white;
}

input {
	-webkit-appearance: none;
	   -moz-appearance: none;
			appearance: none;
}



.page-wrap {
	margin: 0 auto;
	width: 1094px;
}

.logo {
	position: relative;
	height: 103px;
	width: 1022px;
	margin: 24px auto 80px auto;
	padding: 45px 35px 0 35px;
	background: url(../images/logo.png);
	z-index: 2;
}




.separator {
	width: 1094px;
	height: 54px;
	margin: 10px auto;
	background: url(../images/separator.png);

	&_bottom {
		margin-bottom: -24px;
	}
}




.section {
	@include font(18px, 28px, $gotham, 400);
	text-align: center;
	color: white;
	text-shadow: 2px 2px 5px black;
}

.menu {
	margin: 0;
	padding: 0;
}
.menu li {
	display: inline-block;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.menu li a {
	display: block;
	margin: 0;
	padding: 10px 0;
	/*padding: 10px 35px;*/
	@include font(15pt, 1.3em, $gotham, 300);
	color: #b4b8ce;
	text-decoration: none;
	text-align: center;
	white-space: nowrap;
	-webkit-font-smoothing : subpixel-antialiased !important;
	-webkit-text-stroke-width: 0.1px !important;
	text-rendering: geometricPrecision;
	/*text-shadow: 0px 0px 0px !important;
	-webkit-text-stroke-width: 0.1px !important;*/
}

.menu li a:hover {
	border-bottom: #b4b8ce solid 9px;
}

.left {
	float: left;
}

.right {
	float: right;
}

.selected {
	color: white !important;
	border-bottom: white solid 9px;
}

.selected:hover {
	color: white;
	border-bottom: white solid 9px !important;
}
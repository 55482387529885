@charset "UTF-8";
/* --------------------
   Rules
   -------------------- */


.rule {
	clear: both;
	height: 1100px;
	margin: 0 auto;
	width: 1000px;
	/*padding-top: 50px;*/
	margin-top: -48px;
	font-family: $gotham;
	font-weight: 500;
}

.rule-title {
	text-align: center;
	margin-bottom: 35px;
	/*text-shadow: 0 2px 2px rgba(0,0,0,0.4);*/
	@include font(46px, 48px, $gotham, 500);
	letter-spacing: -1.2px;
	text-shadow: 2px 2px 5px black;
}

.rule-block {
	margin: 18px 30px 0 30px;
	padding: 70px 25px 10px 20px;
	float: left;
	width: 395px;
	height: 240px;
	text-align: left;
	font-size: 17px;
	line-height: 26px;
	text-shadow: 2px 2px 3px black;
	letter-spacing: -0.2px;
	white-space: nowrap;
}

.rule-block1 {
	background: url(../images/rule-frame1.png) no-repeat bottom center;
}

.rule-block2 {
	background: url(../images/rule-frame2.png) no-repeat bottom center;
}

.rule-block3 {
	background: url(../images/rule-frame3.png) no-repeat bottom center;
	padding-left: 40px;
	width: 375px;
}
.rule-block3 .rule-capt {
	position: relative;
	margin-bottom: 0;
	line-height: 40px;
	left: -20px;
}

.rule-block4 {
	background: url(../images/rule-frame4.png) no-repeat bottom center;
	padding-left: 30px;
	width: 385px;
}
.rule-block4 .rule-capt {
	position: relative;
	margin-bottom: 20px;
	line-height: 40px;
	left: -10px;
}

.rule-block5 {
	background: url(../images/rule-frame5.png) no-repeat bottom center;
	padding-left: 25px;
	width: 390px;
}

.rule-block6 {
	background: url(../images/rule-frame6.png) no-repeat bottom center;
	padding-left: 25px;
	width: 390px;
}
.rule-block6 .rule-capt {
	margin-bottom: 0;
}

.rule-capt {
	/*display: inline-block;*/
	text-align: center;
	line-height: 49px;
	font-size: 27px;
	width: 394px;
	margin-bottom: 17px;
}

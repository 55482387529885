@charset "UTF-8";
/* --------------------
   Modal window
   -------------------- */

.modal-form {
	display: none;
	position: relative;
	top: 45%;
	margin: 0 auto;
	padding: 27px 20px;
	opacity: 0;
	background-color: #202430;
	box-shadow: 0 0 10px black;
	z-index: 35;
}

/*.modal-close {
	width: 21px;
	height: 21px;
	float: right;
	cursor: pointer;
	display: block;
	color: #c0d6fd;
	font-weight: 600;
	font-size: 20px;
	//line-height: 20px;
}*/

#reserve {
	width: 442px;
	height: 520px;
	margin-top: -205px;
}
#reserve form {
	width: 350px;
	margin: 0 auto;
	@include font(13px, 15px, $gotham, 300);
}

#reserve input {
	width: 100%;
	height: 38px;
	margin-top: 12px;
	margin-bottom: 22px;
	padding: 0 12px;
	border: none;
	outline: none;
	background: #161821;
	line-height: 14px;
	font-size: 14px;
	color: #9390a3;
	transition: box-shadow 0.5s;
}

#reserve input:focus {
	box-shadow: 0 0 6px white;
}

#reserve input[type="submit"] {
	
	width: 374px;
	height: 50px;
	border-radius: 13px;
	background: #962d2d;
	@include font(24px, 26px, $gotham, 400);
	color: white;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.67);
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45);
}

#reserve input[type="submit"]:hover {
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45),
							0 0 7px rgba(254, 237, 200, 0.75); /*#feedc8*/
}

#reserve input[type="submit"]:active {
	background: #6d2626;
	box-shadow: none;
}





#reserve .submit {
	width: 374px;
	height: 50px;
	margin-top: 12px;
	margin-bottom: 22px;
	border-radius: 13px;
	background: #962d2d;
	@include font(24px, 50px, $gotham, 400);
	color: white;
	text-align: center;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.67);
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45);
	cursor: pointer;
}

#reserve .submit:hover {
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45),
							0 0 7px rgba(254, 237, 200, 0.75); /*#feedc8*/
}

#reserve .submit:active {
	background: #6d2626;
	box-shadow: none;
}

.rs-info {
	margin-bottom: 14px;
	@include font(25px, 39px, $gotham, 400);
	text-align: center;
}

.rs-weekday {
	font-size: 18px;
	text-align: center;
}

.rs-title {
	margin-bottom: 14px;
	@include font(30px, 32px, $gotham, 500);
	letter-spacing: -0.4px;
}

.rs-day, .rs-time {
	font-size: 28px;
}








#thanks {
	width: 650px;
	min-height: 190px;
	margin-top: -95px;
	text-align: center;
}
#thanks p {
	margin-top: 26px;
	font-size: 20px;
	margin-bottom: 40px;
	line-height: 30px;
	-webkit-text-size-adjust: none !important;
}
.thanks-title {
	margin-top: 16px;
	font-size: 45px;
}


.red-button {
	width: 210px;
	height: 40px;
	margin: 0 auto;
	border-radius: 10px;
	background: #962d2d;
	@include font(24px, 38px, $gotham, 400);
	color: white;
	text-align: center;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.67);
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45);
	cursor: pointer;
}


.red-button:hover {
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45),
							0 0 7px rgba(254, 237, 200, 0.75); /*#feedc8*/
}

.red-button:active {
	background: #6d2626;
	box-shadow: none;
}



#privacy-policy {
	width: 850px;
	height: 1230px;
	margin-top: -95px;
	padding: 50px;
	font-size: 18px;
}

.privacy-policy-title {
	margin-top: 16px;
	margin-bottom: 35px;
	font-size: 35px;
}

#map {
	width: 850px;
	height: 500px;
	margin-top: -95px;
	padding: 10px;
}


#modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	/*background-color: #000;
	opacity: 0.4;*/
	z-index: 30;
}

#overlay {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/*background-color: #000;
	opacity: 0.4;*/
	background-color: rgba(0,0,0,0.3);
	cursor: pointer;
	z-index: 25;
}
@charset "UTF-8";

// Шрифты
$sans-serif: Arial, Helvetica, sans-serif;
$serif: "Times New Roman", Times, serif;

//$verdana: Verdana, Geneva, sans-serif;
//$mediator: "Mediator", sans-serif;

$gotham: "Gotham Pro", "ArialBold", sans-serif;

// Breakpoints for each query
$tablet-p: 768px;
$tablet-l: 1024px;
$laptop: 1281px;
$desktop: 1400px;
$wide: 1401px;

$row-width: 1226px;

//путь к папке изображений
$i: '../img';

// Цвета
// используем английские названия цвета (red, green, blue, yellow и т.д.)
// если оттенков цвета небольшое количество, стоит использовать модификаторы: dark, middle, light и т.п.;
// Иначе добавлять 3х символьный модификатор оттенка:
// модификатор оттенка складывается из первых символов группы
// Пример #XxYyZz -> color-XYZ; #a9cfdd -> $color-acd;
// если Xx = Yy = Zz, модификатор сокращается до двух символов. (Пример #cbcbcb -> $gray-cb;)
// если X = x = Y = y = Z = z -> модификатор сокращается до одного символа (Пример #666 - $gray-6;)
// В случае совпадении модификаторов оттенка одного цвета нужно оставить только один.

$white:            #fefefe;

$red-c45:          #c6475d;
$red-e67:          #ec6c7d;

$blue-258:         #2c5688;
$blue-79d:         #739dd2;

$gray-eee:         #e4e5e6;
$gray-b0:          #b0b0b0;
$gray-97:          #979797;

$black:            #010101;

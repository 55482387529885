@charset "UTF-8";
/* ====================
   Подключение шрифтов
   ==================== */
/* font: Gotham Pro Regular */
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamProRegular/GothamProRegular.eot");
  src: url("../fonts/GothamProRegular/GothamProRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProRegular/GothamProRegular.svg") format("svg"), url("../fonts/GothamProRegular/GothamProRegular.woff") format("woff"), url("../fonts/GothamProRegular/GothamProRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400; }

/* font: Gotham Pro Bold */
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamProBold/GothamProBold.eot");
  src: url("../fonts/GothamProBold/GothamProBold.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProBold/GothamProBold.woff") format("woff"), url("../fonts/GothamProBold/GothamProBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700; }

/* font: Gotham Pro Black */
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamProBlack/GothamProBlack.eot");
  src: url("../fonts/GothamProBlack/GothamProBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProBlack/GothamProBlack.woff") format("woff"), url("../fonts/GothamProBlack/GothamProBlack.ttf") format("truetype");
  font-style: normal;
  font-weight: 900; }

/* font: Gotham Pro Italic */
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamProItalic/GothamProItalic.eot");
  src: url("../fonts/GothamProItalic/GothamProItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProItalic/GothamProItalic.woff") format("woff"), url("../fonts/GothamProItalic/GothamProItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400; }

/* font: Gotham Pro Medium */
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamProMedium/GothamProMedium.eot");
  src: url("../fonts/GothamProMedium/GothamProMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProMedium/GothamProMedium.svg") format("svg"), url("../fonts/GothamProMedium/GothamProMedium.woff") format("woff"), url("../fonts/GothamProMedium/GothamProMedium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500; }

/* font: Gotham Pro Light */
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamProLight/GothamProLight.eot");
  src: url("../fonts/GothamProLight/GothamProLight.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProLight/GothamProLight.svg") format("svg"), url("../fonts/GothamProLight/GothamProLight.woff") format("woff"), url("../fonts/GothamProLight/GothamProLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 300; }

/* font: Gotham Pro Medium Italic */
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamProMediumItalic/GothamProMediumItalic.eot");
  src: url("../fonts/GothamProMediumItalic/GothamProMediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProMediumItalic/GothamProMediumItalic.woff") format("woff"), url("../fonts/GothamProMediumItalic/GothamProMediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500; }

/* font: Gotham Pro Light Italic */
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamProLightItalic/GothamProLightItalic.eot");
  src: url("../fonts/GothamProLightItalic/GothamProLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProLightItalic/GothamProLightItalic.woff") format("woff"), url("../fonts/GothamProLightItalic/GothamProLightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 300; }

/* font: Gotham Pro Bold Italic */
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamProBoldItalic/GothamProBoldItalic.eot");
  src: url("../fonts/GothamProBoldItalic/GothamProBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProBoldItalic/GothamProBoldItalic.woff") format("woff"), url("../fonts/GothamProBoldItalic/GothamProBoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700; }

/* font: Gotham Pro Black Italic */
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamProBlackItalic/GothamProBlackItalic.eot");
  src: url("../fonts/GothamProBlackItalic/GothamProBlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProBlackItalic/GothamProBlackItalic.woff") format("woff"), url("../fonts/GothamProBlackItalic/GothamProBlackItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 900; }

/* font-family: "CatorzeSemiBold"; */
@font-face {
  font-family: "CatorzeSemiBold";
  src: url("../fonts/CatorzeSemiBold/Catorze27Style1SemiBold.eot");
  src: url("../fonts/CatorzeSemiBold/Catorze27Style1SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/CatorzeSemiBold/Catorze27Style1SemiBold.woff") format("woff"), url("../fonts/CatorzeSemiBold/Catorze27Style1SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* ====================
   Reset
   ==================== */
/* --------------------
   Eric Meyer's CSS Reset
   -------------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* ====================
   Base styles
   ==================== */
/* --------------------
   Base styles
   -------------------- */
html, body {
  height: 100%; }

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased; }

@media screen and (max-device-width: 480px) {
  html {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none; } }

body {
  min-width: 1088px;
  background: url(../images/bg.jpg);
  font-family: "Gotham Pro", "ArialBold", sans-serif;
  font-weight: 400;
  color: white; }
  body.is-no-scroll {
    overflow: hidden; }

b {
  font-family: "Gotham Pro", "ArialBold", sans-serif;
  font-weight: 500; }

h1, h2 {
  text-align: center;
  text-shadow: 2px 2px 5px black; }

h1 {
  margin-bottom: 30px;
  font: 500 42px/44px "Gotham Pro", "ArialBold", sans-serif;
  letter-spacing: -2.4px; }

h2 {
  margin-bottom: 116px;
  font: 300 29px/36px "Gotham Pro", "ArialBold", sans-serif;
  letter-spacing: -0.8px; }

a {
  color: white; }

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.page-wrap {
  margin: 0 auto;
  width: 1094px; }

.logo {
  position: relative;
  height: 103px;
  width: 1022px;
  margin: 24px auto 80px auto;
  padding: 45px 35px 0 35px;
  background: url(../images/logo.png);
  z-index: 2; }

.separator {
  width: 1094px;
  height: 54px;
  margin: 10px auto;
  background: url(../images/separator.png); }
  .separator_bottom {
    margin-bottom: -24px; }

.section {
  font: 400 18px/28px "Gotham Pro", "ArialBold", sans-serif;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 5px black; }

.menu {
  margin: 0;
  padding: 0; }

.menu li {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative; }

.menu li a {
  display: block;
  margin: 0;
  padding: 10px 0;
  /*padding: 10px 35px;*/
  font: 300 15pt/1.3em "Gotham Pro", "ArialBold", sans-serif;
  color: #b4b8ce;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  -webkit-font-smoothing: subpixel-antialiased !important;
  -webkit-text-stroke-width: 0.1px !important;
  text-rendering: geometricPrecision;
  /*text-shadow: 0px 0px 0px !important;
	-webkit-text-stroke-width: 0.1px !important;*/ }

.menu li a:hover {
  border-bottom: #b4b8ce solid 9px; }

.left {
  float: left; }

.right {
  float: right; }

.selected {
  color: white !important;
  border-bottom: white solid 9px; }

.selected:hover {
  color: white;
  border-bottom: white solid 9px !important; }

/* ====================
   Стили блоков сайта
   ==================== */
/* --------------------
   Button
   -------------------- */
.button {
  outline: none;
  border: none;
  border-radius: 7px;
  font: 400 24px/26px "Gotham Pro", "ArialBold", sans-serif;
  color: white;
  cursor: pointer;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.67);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45);
  transition: background 0.3s, color 0.3s, box-shadow 0.3s; }
  .button:hover {
    background-color: white;
    color: #2b303f;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.3); }
  .button:active {
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.3), inset 2px 2px 5px rgba(0, 0, 0, 0.2); }
  .button_red {
    background: #962d2d; }
    .button_red:hover {
      background: #962d2d;
      color: white;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45), 0 0 7px rgba(254, 237, 200, 0.75);
      /*#feedc8*/ }

/* --------------------
   Certificate
   -------------------- */
.certificate {
  clear: both;
  height: 850px;
  margin: -13px auto 0;
  width: 1000px;
  text-align: center; }
  .certificate__title {
    text-align: center;
    margin-bottom: 81px;
    font: 500 44px/46px "Gotham Pro", "ArialBold", sans-serif;
    letter-spacing: -1.9px;
    text-shadow: 2px 2px 5px black; }
  .certificate__text {
    width: 830px;
    margin: 0 auto;
    margin-bottom: 30px;
    font: 400 22px/34px "Gotham Pro", "ArialBold", sans-serif;
    letter-spacing: -0.2px;
    color: #eeeedd;
    text-shadow: 2px 2px 5px black;
    text-align: left; }
  .certificate a {
    color: #eeeedd; }
  .certificate__form {
    width: 412px;
    margin: 80px auto 0; }
  .certificate input {
    width: 376px;
    height: 54px;
    margin-top: 12px;
    margin-bottom: 10px;
    padding: 0 18px;
    background: rgba(0, 0, 0, 0.3);
    font: 300 20px/35px "Gotham Pro", "ArialBold", sans-serif;
    color: #7b8092;
    border: none;
    border-radius: 7px;
    transition: box-shadow 0.5s;
    outline: none; }
    .certificate input:focus {
      box-shadow: 0 0 6px #373f51; }
  .certificate input[type="submit"] {
    width: 412px;
    height: 55px;
    background: #151b26;
    margin-top: 24px;
    margin-bottom: 10px;
    font: 400 22px/55px "Gotham Pro", "ArialBold", sans-serif;
    letter-spacing: -0.8px;
    color: white;
    text-transform: uppercase;
    border-radius: 7px;
    transition: all 0.3s;
    cursor: pointer; }
    .certificate input[type="submit"]:hover {
      box-shadow: 0 0 7px rgba(255, 255, 255, 0.75);
      /*#feedc8*/ }
    .certificate input[type="submit"]:active {
      background: #0f141c;
      box-shadow: none; }

/* --------------------
   Contacts
   -------------------- */
.contacts__container {
  width: 1022px;
  margin: -48px auto 0;
  font-family: "Gotham Pro", "ArialBold", sans-serif;
  font-weight: 400;
  color: #eeeedd;
  zoom: 1; }
  .contacts__container::before {
    content: " ";
    content: " ";
    display: table; }
  .contacts__container::after {
    content: " ";
    content: " ";
    clear: both;
    display: table; }

.contacts__block {
  float: left;
  width: 420px;
  height: 527px;
  margin: 0 15px 37px;
  padding: 30px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.25); }

.contacts__title {
  height: 112px; }

.contacts__main {
  margin-bottom: 7px;
  font: 300 18px/20px "Gotham Pro", "ArialBold", sans-serif;
  text-align: center; }

.contacts__capt {
  margin-bottom: 9px;
  font: 500 33px/35px "Gotham Pro", "ArialBold", sans-serif;
  text-align: center; }

.contacts__city {
  text-align: center;
  font-size: 20px; }

.contacts__item {
  height: 45px;
  margin-top: 24px;
  padding: 15px 0 0 78px;
  font: 300 21px/23px "Gotham Pro", "ArialBold", sans-serif; }
  .contacts__item_address {
    background: url(../images/contact-icon-address.png) 3px center no-repeat;
    padding-top: 10px;
    height: 50px; }
  .contacts__item_phone {
    background: url(../images/contact-icon-phone.png) 9px -4px no-repeat;
    font-size: 17px;
    padding-top: 22px;
    height: 43px; }
  .contacts__item_mail {
    background: url(../images/contact-icon-mail.png) no-repeat; }
  .contacts__item_vk {
    background: url(../images/contact-icon-vk.png) no-repeat; }
  .contacts__item_instagram {
    background: url(../images/contact-icon-instagram.png) no-repeat; }
  .contacts__item a {
    padding-bottom: 3px;
    border-bottom: 1px solid #eeeedd;
    color: #eeeedd;
    text-decoration: none; }

.contacts__map {
  margin: 0 55px;
  height: 550px; }

.contacts__hidden {
  display: none; }

.contacts__infowindow {
  max-width: 250px;
  padding: 10px 0;
  color: black; }
  .contacts__infowindow h3 {
    font: 700 13px/15px "Gotham Pro", "ArialBold", sans-serif;
    font-weight: 700;
    padding-bottom: 10px; }
  .contacts__infowindow p {
    font: 400 13px/15px "Gotham Pro", "ArialBold", sans-serif; }

/* --------------------
   Отзывы
   -------------------- */
.comments {
  clear: both;
  width: 850px;
  margin: 0 auto;
  padding-top: 20px; }
  .comments__block {
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: rgba(18, 21, 31, 0.6);
    font-size: 0; }
    .comments__block:last-child {
      margin-bottom: 45px; }
      .comments__block:last-child::after {
        content: " ";
        position: relative;
        top: 35px;
        display: block;
        height: 3px;
        background-color: #5c6170;
        border-radius: 2px;
        box-shadow: 1px 2px 3px black; }
  .comments__content {
    position: relative;
    padding: 20px; }
  .comments__name {
    display: inline-block;
    vertical-align: top;
    padding: 0 20px 10px 0;
    font: 500 20px/20px "Gotham Pro", "ArialBold", sans-serif; }
  .comments__date {
    display: inline-block;
    vertical-align: top;
    padding-bottom: 10px;
    font: 400 15px/24px "Gotham Pro", "ArialBold", sans-serif;
    color: #9390a3; }
  .comments__text {
    padding-top: 10px;
    font: 400 14px/21px "Gotham Pro", "ArialBold", sans-serif; }

/* --------------------
   Form
   -------------------- */
.form {
  box-sizing: border-box; }
  .form label {
    font: 300 13px/15px "Gotham Pro", "ArialBold", sans-serif;
    color: white; }
  .form input {
    box-sizing: border-box;
    width: 100%;
    height: 38px;
    margin-top: 12px;
    margin-bottom: 22px;
    padding: 0 12px;
    border: none;
    outline: none;
    background: #161821;
    font: 400 14px/14px "Gotham Pro", "ArialBold", sans-serif;
    color: #9390a3;
    transition: box-shadow 0.5s; }
    .form input.is-error {
      box-shadow: 0 0 6px red; }
    .send-comment .form input {
      background-color: rgba(18, 21, 31, 0.6);
      padding: 25px 20px;
      font: 400 20px/20px "Gotham Pro", "ArialBold", sans-serif;
      border-radius: 10px; }
  .form input[type="submit"] {
    min-height: 50px;
    border-radius: 7px;
    background: #962d2d;
    font: 400 24px/26px "Gotham Pro", "ArialBold", sans-serif;
    color: white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.67);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45); }
    .send-comment .form input[type="submit"] {
      display: inline-block;
      width: auto;
      padding: 0 40px;
      text-transform: uppercase; }
  .form textarea {
    box-sizing: border-box;
    resize: vertical;
    width: 100%;
    height: 64px;
    margin-top: 12px;
    margin-bottom: 22px;
    padding: 10px 12px;
    border: none;
    outline: none;
    background: #161821;
    font: 400 14px/14px "Gotham Pro", "ArialBold", sans-serif;
    color: #9390a3;
    transition: box-shadow 0.5s; }
    .form textarea.is-error {
      box-shadow: 0 0 6px red; }
    .send-comment .form textarea {
      background-color: rgba(18, 21, 31, 0.6);
      padding: 15px 20px;
      font: 400 20px/20px "Gotham Pro", "ArialBold", sans-serif;
      border-radius: 10px;
      min-height: 200px; }

/* --------------------
   Modal window
   -------------------- */
.modal-form {
  display: none;
  position: relative;
  top: 45%;
  margin: 0 auto;
  padding: 27px 20px;
  opacity: 0;
  background-color: #202430;
  box-shadow: 0 0 10px black;
  z-index: 35; }

/*.modal-close {
	width: 21px;
	height: 21px;
	float: right;
	cursor: pointer;
	display: block;
	color: #c0d6fd;
	font-weight: 600;
	font-size: 20px;
	//line-height: 20px;
}*/
#reserve {
  width: 442px;
  height: 520px;
  margin-top: -205px; }

#reserve form {
  width: 350px;
  margin: 0 auto;
  font: 300 13px/15px "Gotham Pro", "ArialBold", sans-serif; }

#reserve input {
  width: 100%;
  height: 38px;
  margin-top: 12px;
  margin-bottom: 22px;
  padding: 0 12px;
  border: none;
  outline: none;
  background: #161821;
  line-height: 14px;
  font-size: 14px;
  color: #9390a3;
  transition: box-shadow 0.5s; }

#reserve input:focus {
  box-shadow: 0 0 6px white; }

#reserve input[type="submit"] {
  width: 374px;
  height: 50px;
  border-radius: 13px;
  background: #962d2d;
  font: 400 24px/26px "Gotham Pro", "ArialBold", sans-serif;
  color: white;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.67);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45); }

#reserve input[type="submit"]:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45), 0 0 7px rgba(254, 237, 200, 0.75);
  /*#feedc8*/ }

#reserve input[type="submit"]:active {
  background: #6d2626;
  box-shadow: none; }

#reserve .submit {
  width: 374px;
  height: 50px;
  margin-top: 12px;
  margin-bottom: 22px;
  border-radius: 13px;
  background: #962d2d;
  font: 400 24px/50px "Gotham Pro", "ArialBold", sans-serif;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.67);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45);
  cursor: pointer; }

#reserve .submit:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45), 0 0 7px rgba(254, 237, 200, 0.75);
  /*#feedc8*/ }

#reserve .submit:active {
  background: #6d2626;
  box-shadow: none; }

.rs-info {
  margin-bottom: 14px;
  font: 400 25px/39px "Gotham Pro", "ArialBold", sans-serif;
  text-align: center; }

.rs-weekday {
  font-size: 18px;
  text-align: center; }

.rs-title {
  margin-bottom: 14px;
  font: 500 30px/32px "Gotham Pro", "ArialBold", sans-serif;
  letter-spacing: -0.4px; }

.rs-day, .rs-time {
  font-size: 28px; }

#thanks {
  width: 650px;
  min-height: 190px;
  margin-top: -95px;
  text-align: center; }

#thanks p {
  margin-top: 26px;
  font-size: 20px;
  margin-bottom: 40px;
  line-height: 30px;
  -webkit-text-size-adjust: none !important; }

.thanks-title {
  margin-top: 16px;
  font-size: 45px; }

.red-button {
  width: 210px;
  height: 40px;
  margin: 0 auto;
  border-radius: 10px;
  background: #962d2d;
  font: 400 24px/38px "Gotham Pro", "ArialBold", sans-serif;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.67);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45);
  cursor: pointer; }

.red-button:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45), 0 0 7px rgba(254, 237, 200, 0.75);
  /*#feedc8*/ }

.red-button:active {
  background: #6d2626;
  box-shadow: none; }

#privacy-policy {
  width: 850px;
  height: 1230px;
  margin-top: -95px;
  padding: 50px;
  font-size: 18px; }

.privacy-policy-title {
  margin-top: 16px;
  margin-bottom: 35px;
  font-size: 35px; }

#map {
  width: 850px;
  height: 500px;
  margin-top: -95px;
  padding: 10px; }

#modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  /*background-color: #000;
	opacity: 0.4;*/
  z-index: 30; }

#overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: #000;
	opacity: 0.4;*/
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 25; }

/* --------------------
   Quest block
   -------------------- */
.quest-block {
  position: relative;
  float: left;
  width: 472px;
  height: 311px;
  padding: 6px;
  border: white solid 6px;
  margin: 0 25px;
  margin-bottom: 50px;
  transition: box-shadow 0.3s ease-out; }

.quest-block:hover {
  box-shadow: 0 0 10px white; }

.quest-block:hover img {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02); }

.quest-title span {
  display: block;
  transition: all 1.5s ease-out; }

.quest-block .quest-title {
  transition: all 1.5s ease-out; }

.quest-block:hover .quest-title {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02); }

.inactive:hover {
  box-shadow: none; }

.inactive:hover img, .inactive:hover .quest-title, .quest-block:hover .quest-title span {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.quest-img {
  position: relative;
  transition: -webkit-transform 1.5s ease-out;
  transition: transform 1.5s ease-out; }

.quest-title {
  position: relative;
  color: white;
  font-size: 40px;
  letter-spacing: -2px;
  line-height: 52px;
  font-family: CatorzeSemiBold;
  /* найти замену */
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  height: 52px;
  background: rgba(0, 0, 0, 0.5);
  width: 472px;
  margin: 118px auto 0 auto; }

.quest-description {
  text-align: left;
  z-index: 20;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  position: absolute; }

.quest-info {
  position: absolute;
  left: 34px;
  bottom: 22px; }

.quest-person {
  display: block;
  font: 500 14px/16px "Gotham Pro", "ArialBold", sans-serif;
  color: white; }

.quest-map {
  display: block;
  margin-top: 13px;
  font: 500 12px/14px "Gotham Pro", "ArialBold", sans-serif;
  color: white; }

.quest-address {
  display: inline-block;
  width: 230px;
  line-height: 19px;
  font-size: 15px;
  letter-spacing: -1px; }

.quest-description strong {
  font-size: 24px;
  margin-left: 5px; }

.icon-person {
  display: inline-block;
  vertical-align: text-bottom;
  width: 18px;
  height: 24px;
  margin-right: 2px;
  background: url(../images/icon-person.png) center no-repeat; }

.icon-person-grey {
  display: inline-block;
  vertical-align: text-bottom;
  width: 18px;
  height: 24px;
  margin-right: 2px;
  background: url(../images/icon-person-grey.png) center no-repeat; }

.icon-map {
  display: inline-block;
  /*vertical-align: top;*/
  width: 23px;
  height: 23px;
  margin-right: 9px;
  background: url(../images/icon-map.png); }

.icon-clock {
  display: inline-block;
  width: 39px;
  height: 39px;
  background: url(../images/icon-clock.png);
  margin: 0 auto; }

.icon-person2 {
  display: inline-block;
  vertical-align: text-bottom;
  width: 25px;
  height: 27px;
  margin-right: 2px;
  background: url(../images/icon-person2.png) center no-repeat; }

.icon-person-grey2 {
  display: inline-block;
  vertical-align: text-bottom;
  width: 25px;
  height: 27px;
  margin-right: 2px;
  background: url(../images/icon-person-grey2.png) center no-repeat; }

.icon-map2 {
  display: inline-block;
  /*vertical-align: top;*/
  width: 30px;
  height: 30px;
  margin-right: 14px;
  background: url(../images/icon-map2.png); }

.icon-clock2 {
  display: inline-block;
  vertical-align: bottom;
  width: 35px;
  height: 35px;
  background: url(../images/icon-clock2.png);
  margin-right: 15px;
  margin-top: 5px; }

.quest-block .quest-info2 {
  margin-left: -24px; }

.quest-info2 {
  position: absolute;
  bottom: 33px;
  left: 24px;
  width: 100%;
  color: white;
  font-size: 17px;
  text-align: center;
  margin-top: 12px; }

/* --------------------
   Quest
   -------------------- */
.quest-block2 {
  height: 297px; }

.hole {
  position: absolute;
  z-index: 1;
  left: 454px;
  background: url(../images/hole.png);
  width: 165px;
  height: 82px; }

.black-block {
  position: absolute;
  left: 0;
  z-index: -10;
  margin-top: -128px;
  background: black;
  width: 100%;
  height: 397px;
  min-width: 1092px;
  overflow: hidden; }

.block-img {
  margin: 0 auto;
  left: 291px;
  position: relative;
  width: 599px;
  height: 397px; }

.quest-map2 {
  position: absolute;
  top: 302px;
  background: url(../images/address-background.png);
  width: 100%;
  height: 52px;
  padding: 14px 32px; }

.quest-info2 strong {
  font-size: 30px; }

.quest-info2 .quest-person {
  margin-top: 9px; }

.quest-map2 .quest-address {
  line-height: 18px;
  font-size: 15px;
  width: 230px; }

.map-link {
  display: inline-block;
  padding-left: 48px;
  padding-top: 5px;
  font-size: 13px;
  color: #dc6161; }

.map-link:hover {
  cursor: pointer;
  text-decoration: underline; }

.schedule-tip {
  font: 20px agora-regular;
  text-align: center; }

.current a {
  color: white; }

.sc-line {
  clear: both;
  height: 50px;
  margin-top: 5px;
  margin-bottom: 5px; }

.sc-line td {
  vertical-align: middle; }

.sc-date {
  width: 120px;
  vertical-align: middle;
  font: 300 44px/50px "Gotham Pro", "ArialBold", sans-serif;
  letter-spacing: -2px;
  color: white; }

.sc-weekend .sc-date, .sc-weekend .sc-price {
  color: #b8b9de; }

.sc-weekend .icon-ruble-small {
  background: url(../images/icon-ruble-small2.png); }

.sc-weekend .sc-hline {
  border-color: #b8b9de; }

.sc-weekday {
  width: 120px;
  font-size: 13px;
  margin-right: 9px; }

.sc-time {
  width: 62px;
  height: 45px;
  border: black solid 1px;
  border-radius: 7px;
  font: 300 17px/45px "Gotham Pro", "ArialBold", sans-serif;
  color: black;
  /*border: 2px solid #ff5959;*/
  text-align: center;
  letter-spacing: -1px;
  box-shadow: none;
  /*display: inline-block;
	text-shadow: 0 6px 3px black;*/ }
  .sc-time.is-active {
    border-color: white;
    color: white;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.3), inset 2px 3px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer; }
    .sc-time.is-active:hover {
      background-color: white;
      color: #2b303f;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.3); }
    .sc-time.is-active:active {
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.3), inset 2px 2px 5px rgba(0, 0, 0, 0.2); }

.hline {
  width: 82px;
  margin: 0 auto;
  border-bottom: white solid 5px; }

.quest-descripion {
  margin-top: 23px;
  padding-left: 23px;
  font-size: 14px;
  line-height: 21px; }

.icon-ruble-small {
  display: inline-block;
  vertical-align: text-top;
  width: 9px;
  height: 11px;
  background: url(../images/icon-ruble-small.png); }

.sc-price {
  padding-top: 11px;
  padding-bottom: 18px; }

.sc-hline {
  display: inline-block;
  border-bottom: solid white 1px;
  margin-bottom: 3px; }

.quest-block3 {
  position: absolute;
  margin-top: -128px;
  /*background: black;*/
  width: auto;
  height: 397px;
  min-width: 1092px;
  overflow: hidden; }

#chimera .icon-person-grey {
  background-image: url(../images/icon-person-black.png); }

.winners-tab {
  width: 1000px;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-top: 20px; }

.schedule-tab {
  clear: both;
  width: 850px;
  margin: 0 auto;
  padding-bottom: 30px;
  /*border-top: #5c6170 solid 3px;*/
  padding-top: 20px; }

/* --------------------
   Rules
   -------------------- */
.rule {
  clear: both;
  height: 1100px;
  margin: 0 auto;
  width: 1000px;
  /*padding-top: 50px;*/
  margin-top: -48px;
  font-family: "Gotham Pro", "ArialBold", sans-serif;
  font-weight: 500; }

.rule-title {
  text-align: center;
  margin-bottom: 35px;
  /*text-shadow: 0 2px 2px rgba(0,0,0,0.4);*/
  font: 500 46px/48px "Gotham Pro", "ArialBold", sans-serif;
  letter-spacing: -1.2px;
  text-shadow: 2px 2px 5px black; }

.rule-block {
  margin: 18px 30px 0 30px;
  padding: 70px 25px 10px 20px;
  float: left;
  width: 395px;
  height: 240px;
  text-align: left;
  font-size: 17px;
  line-height: 26px;
  text-shadow: 2px 2px 3px black;
  letter-spacing: -0.2px;
  white-space: nowrap; }

.rule-block1 {
  background: url(../images/rule-frame1.png) no-repeat bottom center; }

.rule-block2 {
  background: url(../images/rule-frame2.png) no-repeat bottom center; }

.rule-block3 {
  background: url(../images/rule-frame3.png) no-repeat bottom center;
  padding-left: 40px;
  width: 375px; }

.rule-block3 .rule-capt {
  position: relative;
  margin-bottom: 0;
  line-height: 40px;
  left: -20px; }

.rule-block4 {
  background: url(../images/rule-frame4.png) no-repeat bottom center;
  padding-left: 30px;
  width: 385px; }

.rule-block4 .rule-capt {
  position: relative;
  margin-bottom: 20px;
  line-height: 40px;
  left: -10px; }

.rule-block5 {
  background: url(../images/rule-frame5.png) no-repeat bottom center;
  padding-left: 25px;
  width: 390px; }

.rule-block6 {
  background: url(../images/rule-frame6.png) no-repeat bottom center;
  padding-left: 25px;
  width: 390px; }

.rule-block6 .rule-capt {
  margin-bottom: 0; }

.rule-capt {
  /*display: inline-block;*/
  text-align: center;
  line-height: 49px;
  font-size: 27px;
  width: 394px;
  margin-bottom: 17px; }

/* --------------------
   Send comment form
   -------------------- */
.send-comment {
  width: 850px;
  margin: 0 auto;
  padding: 30px 0 20px; }
  .send-comment__title {
    margin: 0 0 20px;
    font: 500 30px/30px "Gotham Pro", "ArialBold", sans-serif; }
  .send-comment__button-holder {
    text-align: center; }

/* --------------------
   Tabs
   -------------------- */
.tabs__list {
  position: relative;
  left: 50%;
  float: left;
  margin-top: 21px; }

.tabs__tab {
  display: inline-block;
  position: relative;
  left: -50%;
  margin: 0 10px;
  padding-top: 20px;
  padding-bottom: 13px;
  font: 500 17px/19px "Gotham Pro", "ArialBold", sans-serif;
  color: #807790;
  transition: color 0.4s;
  text-align: center;
  text-shadow: 1px 2px 3px black;
  text-transform: uppercase;
  cursor: pointer; }
  .tabs__tab:hover {
    color: white; }
  .tabs__tab.current {
    color: white;
    border-bottom: #5c6170 solid 6px; }

.tabs__line {
  clear: both;
  margin: 0 auto;
  background: #5c6170;
  width: 850px;
  height: 3px;
  box-shadow: 1px 2px 3px black; }

.tabs__content {
  display: none; }
  .tabs__content.current {
    display: block; }

/* --------------------
   Winners
   -------------------- */
#photo-view {
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow-y: scroll; }

#photo {
  position: relative;
  margin: 0 auto;
  z-index: 35;
  display: block;
  height: auto; }

#photo img {
  width: 100%;
  height: auto; }

.magnify .info {
  opacity: 0; }

.magnify:hover .info {
  opacity: 1; }

#photo .info,
.magnify .info {
  position: absolute;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  -webkit-transition: all 1s, opacity 0.5s;
  -o-transition: all 1s, opacity 0.5s;
  transition: all 1s, opacity 0.5s; }

.info .caption {
  height: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s; }

.big .info .caption {
  height: 30px;
  opacity: 1;
  visibility: visible; }

.big .info {
  padding: 30px !important; }

.big .time span,
.big .date span {
  font-size: 30px !important; }

#photo .time,
#photo .date,
.magnify .time,
.magnify .date {
  font: 500 22px/24px "Gotham Pro", "ArialBold", sans-serif;
  text-shadow: 0 0 20px black, 0 0 20px black, 0 0 20px black;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s; }

#photo .time span,
#photo .date span {
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s; }

#photo .time,
.magnify .time {
  position: absolute; }

#photo .time span,
.magnify .time span {
  line-height: 50px;
  display: inline-block; }

#photo .time span:before,
.magnify .time span:before {
  background: url(../images/clock.png);
  content: '';
  display: inline-block;
  width: 60px;
  height: 67px;
  vertical-align: middle;
  margin: -7px; }

#photo .date,
.magnify .date {
  position: relative;
  float: right;
  text-align: right; }

#photo .date span:before,
.magnify .date span:before {
  background: url(../images/calendar.png);
  content: '';
  display: inline-block;
  width: 64px;
  height: 64px;
  vertical-align: middle;
  margin: -7px; }

.magnify {
  position: relative;
  cursor: pointer;
  width: 320px;
  height: 320px;
  display: inline-block;
  margin: 4px; }

.magnify img {
  width: 100%;
  height: 100%; }

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 25;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  min-height: 760px; }

.years,
.months {
  width: 850px;
  margin: 0 auto; }

.years a,
.months a {
  font-size: 15px;
  padding: 10px 0;
  text-shadow: 1px 2px 3px black;
  text-decoration: none; }

.months {
  display: table;
  width: 870px;
  margin-bottom: 20px; }

.months li {
  display: table-cell;
  float: none; }

.years {
  text-align: center; }

.years li {
  width: 60px;
  display: inline-block; }

.years li a,
.months li a {
  display: block;
  text-align: center;
  font-family: "Gotham Pro", "ArialBold", sans-serif;
  font-weight: 500;
  padding: 8px 0;
  margin: 0 8px;
  color: #807790;
  border-bottom: transparent solid 6px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s; }

.years li a:hover,
.months li a:hover {
  color: white; }

.years li a.current,
.months li a.current {
  color: white;
  border-bottom: #5c6170 solid 6px; }

.photos {
  position: relative;
  min-height: 200px;
  opacity: 1;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s; }

.loading:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: url(../images/loading-big.gif) center 100px no-repeat;
  background-size: 60px 64px; }

.loading * {
  opacity: 0.7; }

.img-loader {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  overflow: hidden; }

/* --------------------
   Youtube video
   -------------------- */
#playYoutube {
  position: absolute;
  /*top: -397px;*/
  left: 538px;
  width: 550px;
  /*height: 397px;*/
  height: 300px;
  cursor: pointer;
  background: url(../images/YouTube-icon-dark.png) 50% 70% no-repeat;
  opacity: 0.4;
  -webkit-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s; }

#playYoutube:before {
  display: block;
  width: 100%;
  height: 100%;
  background: url(../images/YouTube-icon-full_color.png) 50% 70% no-repeat;
  opacity: 0;
  -webkit-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
  content: ''; }

#playYoutube:hover:before {
  opacity: 1; }

#playYoutube:hover {
  opacity: 0.8; }

/* ====================
   Стили секций сайта
   ==================== */
/* --------------------
   Footer
   -------------------- */
.footer {
  width: 1058px;
  height: 90px;
  margin: 50px auto 35px;
  font-size: 14px; }

.footer b {
  display: inline-block;
  margin-bottom: 8px; }

.payment {
  float: left;
  width: 310px;
  margin-top: 12px; }

.icon-ruble {
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 33px;
  background: url(../images/icon-ruble.png); }

.icon-ruble2 {
  display: inline-block;
  vertical-align: baseline;
  width: 13px;
  height: 18px;
  margin-left: 5px;
  background: url(../images/icon-ruble2.png); }

.address {
  float: left;
  width: 438px;
  text-align: center;
  font-size: 13px;
  line-height: 16px; }

.social {
  float: left;
  text-align: right;
  width: 310px;
  margin-top: 12px; }

.icon-instagram {
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icon-instagram.png);
  height: 32px;
  width: 32px;
  margin-left: 8px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-out; }

.icon-vk {
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icon-vk.png);
  height: 32px;
  width: 32px;
  margin-left: 8px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-out; }

.icon-vk:hover, .icon-instagram:hover, .privacy-policy:hover {
  box-shadow: 0 0 6px rgba(255, 255, 255, 0.6); }

.privacy-policy:hover {
  background: white; }

.privacy-policy {
  background: #6d707a;
  color: #2b303f;
  margin: 0 auto;
  width: 230px;
  height: 18px;
  line-height: 18px;
  margin-top: 6px;
  transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
  cursor: pointer;
  margin-bottom: 15px; }

/* ====================
   CSS3 анимации
   ==================== */
@keyframes rotate {
  to {
    transform: rotate(360deg); } }

@keyframes bounce-up {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(0, -30%); }
  100% {
    transform: translate(0, 0); } }

@keyframes bounce-down {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(0, 30%); }
  100% {
    transform: translate(0, 0); } }

@keyframes bounce-left {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(-30%, 0); }
  100% {
    transform: translate(0, 0); } }

@keyframes bounce-right {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(30%, 0); }
  100% {
    transform: translate(0, 0); } }

@keyframes loader-bounce {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0.3); } }

/* ====================
   Медиа-запросы
   ==================== */
